/*
-----------------------------------------------
top
-----------------------------------------------
*/

@import "_setting";
@import "_function";
@import "_mixin";

/* -------------------------------------------------
override common css
------------------------------------------------- */
.g-header
	margin-bottom 0
.g-footer-nav
	display none
.c-header
	padding 0
	border-bottom none

/* -------------------------------------------------
common
------------------------------------------------- */
.animated
	animation-duration 1s
	animation-fill-mode both

.animated.infinite
	animation-iteration-count infinite

.animated.hinge
	animation-duration 2s

@keyframes pulse
	0%
		transform scale3d(1, 1, 1)
	50%
		transform scale3d(1.1, 1.1, 1.1)
	100%
		transform scale3d(1, 1, 1)

.pulse
	animation-name pulse

/* -------------------------------------------------
header
------------------------------------------------- */

/* top
------------------------------------------------- */
.c-header-top
	margin-bottom 60px
	padding 0
	text-align center
	.c-wrapper
		position relative
		width $base_width
		height 300px
		margin 0 auto
		text-align left
	.title
		position absolute
		.name
			fz-rem(18)
			font-weight bold
			line-height 1.3
			text-align right
			color #FFF
	.illust
		position absolute

.c-header-top-spring
	background #75B887
	.title
		top 54px
		left 478px
		img
			margin 0 0 30px
	.illust
		bottom 0
		left 12px

.c-header-top-summer
	background #7B89CA
	.title
		top 50px
		left 18px
		img
			margin 0 0 30px
	.illust
		top 24px
		left 516px

.c-header-top-autumn
	background #E0ACC0
	.title
		top 50px
		left 18px
		img
			margin 0 0 30px
		.name
			color #62504E
	.illust
		bottom 0
		left 536px

.c-header-top-winter
	background #D65944
	.title
		top 54px
		left 478px
		img
			margin 0 0 30px
	.illust
		bottom 0
		left 30px

/* daiichi daini
------------------------------------------------- */
.c-header-daiichi
.c-header-daini
	margin-top 40px
	margin-bottom 60px
	text-align center
	.c-wrapper
		position relative
		width $base_width
		margin 0 auto
	header
		margin 0 0 10px
		text-align left
		border-bottom 10px solid #72AED1
		background #EAE2C8 url(../img/daiichi/index/header_bg.png) 18px bottom no-repeat
		h2
			position absolute
			top 125px
			left 25px
			font-size 5px
		.photo
			text-align right
	p
		fz-rem(19)
		line-height 1.3

.c-header-daini
	header
		background #D3DCC5 url(../img/daini/index/header_bg.png) 3px 19px no-repeat
		border-bottom 10px solid #7BB982

/* -------------------------------------------------
contents
------------------------------------------------- */

/* taisetsu
------------------------------------------------- */
.taisetsu
	margin 0 0 80px
	h2
		margin 0 0 33px 1px
		font-size 10px
	.wrapper
		position relative

.slick
	visibility hidden
	width 978px
	margin 0 1px
	height 290px
.slick-slide
	padding 0 19px
	img
		width auto
		max-width 100%
.slick-prev
.slick-next
	overflow hidden
	position absolute
	top 50%
	margin -19px 0 0 0
	padding 0
	width 21px
	height 38px
	text-indent 200%
	white-space nowrap
	outline-width 0
	cursor pointer
	border none
	background-color transparent
	background-position left top
	background-repeat no-repeat
	rollover-alpha()
.slick-prev
	left -31px
	background-image url(../img/top/taisetsu_icon_prev.png)
.slick-next
	right -31px
	background-image url(../img/top/taisetsu_icon_next.png)

//.owl-carousel
//	width 940px
//	margin 0 20px
//	.box
//		background url(../img/top/taisetsu_ongaku.jpg) left top no-repeat
//.owl-prev
//.owl-next
//	overflow hidden
//	position absolute
//	top 50%
//	margin -19px 0 0 0
//	width 21px
//	height 38px
//	text-indent 100%
//	white-space nowrap
//	cursor pointer
//	background-position left top
//	background-repeat no-repeat
//	rollover-alpha()
//.owl-prev
//	left -41px
//	background-image url(../img/top/taisetsu_icon_prev.png)
//.owl-next
//	right -41px
//	background-image url(../img/top/taisetsu_icon_next.png)
//
//.bx-wrapper
//	margin-bottom 0
//	.bx-viewport
//		left 0
//		border none
//		background-color transparent
//		box-shadow none
//
//.slider
//	.slide
//		rollover-alpha()
//
//.slider-button-prev
//.slider-button-next
//	overflow hidden
//	position absolute
//	top 50%
//	margin -19px 0 0 0
//	.bx-prev
//	.bx-next
//		display block
//		overflow hidden
//		width 21px
//		height 38px
//		text-indent 100%
//		white-space nowrap
//		cursor pointer
//		background-position left top
//		background-repeat no-repeat
//		rollover-alpha()
//.slider-button-prev
//	left -20px
//	.bx-prev
//		background-image url(../img/top/taisetsu_icon_prev.png)
//.slider-button-next
//	right -20px
//	.bx-next
//		background-image url(../img/top/taisetsu_icon_next.png)
//
//.swiper
//	overflow hidden
//	width 940px
//	margin 0 0 0 20px
//.swiper-container
//	width 978px
//	height 290px
//.swiper-slide
//	a
//		rollover-alpha()
//	img
//		width 288px
//		height 290px
//.swiper-button-prev
//.swiper-button-next
//	position absolute
//	width 21px
//	height 38px
//	top 125px
//	cursor pointer
//	background-position left top
//	background-repeat no-repeat
//	rollover-alpha()
//.swiper-button-prev
//	left -20px
//	background-image url(../img/top/taisetsu_icon_prev.png)
//.swiper-button-next
//	right -20px
//	background-image url(../img/top/taisetsu_icon_next.png)

/* information
------------------------------------------------- */
.top-information
	float left
	width 640px
	min-height 310px
	margin 0 0 70px
	header
		overflow hidden
		padding 0 0 10px
		border-bottom 2px solid #C5B26B
		.title
			float left
			margin 0 0 0 3px
			font-size 10px
		.button
			float right
			a
				display block
				padding 0 0 0 12px
				background url(../img/common/information_list_icon_arw.png) left center no-repeat
	.information-list
		dt
			width 148px
		.text
			margin-left 218px
			font-weight normal
			color #333

/* banner
------------------------------------------------- */
.top-banner
	float right
	width 300px
	margin 0 0 60px
	ul
		list-style-type none
	li
		margin 0 0 20px
		&.last
			margin-bottom 0
		&:last-child
			margin-bottom 0
		a, .button
			display flex
			justify-content space-between
			align-items center
			overflow hidden
			height 82px
			text-decoration none
			border 4px solid #EFEFEF
			//rollover-alpha()
			transition-property border
			transition-duration 0.3s
			&:hover
				border-color #DFD8B5
				.illust
					animation-duration 1s
					animation-fill-mode both
					animation-name pulse
		.title
			margin 0 0 0 19px
			//font-size 10px
			//line-height 1
			fz-rem(18)
			font-family $font_serif
			font-weight 400
			line-height 1.2

		.illust
			width auto
		&.large
			a
				height auto
				border none
				rollover-alpha()
		&.reserve
			a, .button
				flex-direction row-reverse
				//justify-content flex-start
				justify-content flex-end
				height auto
				min-height 42px
				padding 24px 4px
				border none
				background #CAC07E
				text-decoration none
				transition background 0.5s ease
				&:hover
					background #d9ce86
			.button
				border-color #CCC
				background #CCC
				&:hover
					.illust
						animation none
			.message
				margin 5px 0 0
				line-height 1.2
				color #F00
			.illust
				margin 0 0 0 24px
		&.blog
			a, .button
				height auto
				min-height 42px
				padding 14px 4px
				background #ebebeb
				border none
				text-decoration none
				transition background 0.5s ease
				&:hover
					background #f2f2f2
			.illust
				box-sizing border-box
				overflow hidden
				width 64px
				margin 0 10px 0 0
				border-radius 50%
				border 3px solid #FFF
				img
					width 100%
					height auto

//.top-banner-bk
//	float right
//	width 300px
//	margin 0 0 60px
//	ul
//		list-style-type none
//	li
//		margin 0 0 20px
//		&.last
//			margin-bottom 0
//		&:last-child
//			margin-bottom 0
//		a
//			display block
//			position relative
//			overflow hidden
//			height 82px
//			border 4px solid #EFEFEF
//			//rollover-alpha()
//			transition-property border
//			transition-duration 0.3s
//			&:hover
//				border-color #DFD8B5
//				.illust
//					animation-duration 1s
//					animation-fill-mode both
//					animation-name pulse
//		.title
//			position absolute
//			top 32px
//			left 19px
//			&.multiple
//				top 19px
//		.illust
//			position absolute
//			top 0
//			right 0
//		&.large
//			a
//				height auto
//				border none
//				rollover-alpha()
//		&.reserve
//			a
//				border-color #CAC07E
//				background #CAC07E
//
/* map
------------------------------------------------- */
.top-map
	clear both
	overflow hidden
	margin 0 0 50px
	padding 10px
	border 1px solid #EAE2C8
	div
		float left
	dl
		float right
		width 290px
		padding 15px 0 0
	dt
		margin 0 0 1em
		fz-rem(20)
		font-weight bold
		line-height 1.6
	dd
		margin 0 0 5px
		fz-rem(16)
		line-height 1.6

/* contents
------------------------------------------------- */
.top-contents
	//overflow hidden
	clear both
	width $base_width
	header
		overflow hidden
		margin 0 0 50px
		padding 0 0 10px
		border-bottom 2px solid #C5B26B
		.title
			float left
			margin 0 0 0 3px
			font-size 10px
		.button
			float right
			a
				display block
				padding 0 0 0 12px
				background url(../img/common/information_list_icon_arw.png) left center no-repeat
	ul
		list-style-type none
		width $base_width + 40px
	li
		float left
		width 300px
		margin 0 40px 35px 0
		line-height 1.3
		a
			display block
			//rollover-alpha()
			text-decoration none
			&:hover
				.illust
					animation-duration 1s
					animation-fill-mode both
					animation-name pulse
				.text
					text-decoration underline
	.illust
		display inline
		img
			margin 0 20px 10px 0
			vertical-align middle
	.title
		display inline
		font-size 10px
		img
			vertical-align middle
	.text
		fz-rem(14)
		line-height 1.6

/* en
------------------------------------------------- */
.en
	clear both
	overflow hidden
	margin 0 0 70px
	ul
		list-style-type none
	a
		display block
		position relative
		width 480px
		height 160px
		//rollover-alpha()
		&:hover
			.photo
				animation-duration 1s
				animation-fill-mode both
				animation-name pulse
	.title
		position absolute
		top 57px
		left 30px
	.photo
		position absolute
		top 16px
		right 20px
	.daiichi
		float left
		a
			background #74AFCF
	.daini
		float right
		a
			background #7DB884

@media screen and (max-width: $viewport_width)
	.c-header-top
		margin-bottom 25px
		.c-wrapper
			width auto
			height 230px
		.title
			position relative
			top 0
			left 0
			padding 20px 20px 0
			img
				width 65%
				max-width 428px
				margin 0 0 20px
			.name
				margin-left 20px
				fz-rem(14)
				text-align left
	.c-header-top-spring
		.title
			top 0
			left 0
		.illust
			left auto
			right 10px
			img
				width 210px
	.c-header-top-summer
		.title
			top 0
			left 0
		.illust
			top 90px
			left auto
			right 10px
			img
				width 230px
	.c-header-top-autumn
		.title
			top 0
			left 0
		.illust
			left auto
			right 10px
			img
				width 220px
	.c-header-top-winter
		.title
			top 0
			left 0
		.illust
			left auto
			right 10px
			img
				width 210px
	
	.c-header-daiichi
	.c-header-daini
		margin-top 0
		margin-bottom 25px
		.c-wrapper
			width auto
		header
			border-bottom-width 5px
			background-size auto 100%
			h2
				top 70px
				left 25px
				img
					width 70%
			.photo
				img
					width 60%
		p
			margin 0 10px
			fz-rem(16)
	.c-header-daini
		header
			background-size auto 80%
	
	.taisetsu
		margin-bottom 30px
		h2
			margin-left 15px
			margin-bottom 20px
			img
				width 90%
				max-width 625px
		.wrapper
			padding 0 155px
	.slick
		width auto
		height auto
		margin 0 10px
	.slick-slide
		padding 5px
	
	//.slider-button-prev
	//	left 40px
	//.slider-button-next
	//	right 40px
	
	.top-information
		float none
		width auto
		min-height 0
		margin-bottom 0
		padding 0 12px 30px
		header
			.title
				margin-left 0
		.information-list
			dt
				width auto
			.text
				margin-left 0
	
	.top-banner
		overflow hidden
		float none
		width auto
		margin-bottom 0
		padding 30px 12px
		border-top 2px solid #8890CF
		li
			margin-bottom 10px
			a, .button
				height 40px
			.title
				margin-left 12px
				img
					height 13px
			.illust
				img
					height 40px
			&.reserve
				a, .button
					flex-direction row
					justify-content space-between
					min-height 32px
					padding 14px 4px
				.message
					fz-rem(10)
				.title
					margin-left 12px
					fz-rem(14)
					img
						height 28px
				.illust
					margin-left 0
					margin-right 5px
					img
						height 30px
			&.blog
				a, .button
					height auto
					min-height initial
					padding 5px 0
				.illust
					width 32px
					margin 0 3px 0 0
					border 2px solid #FFF

	//.top-banner-bk
	//	overflow hidden
	//	float none
	//	width auto
	//	margin-bottom 0
	//	padding 30px 12px
	//	border-top 2px solid #8890CF
	//	li
	//		margin-bottom 10px
	//		a
	//			height 40px
	//		.title
	//			top 14px
	//			left 12px
	//			img
	//				height 13px
	//			&.multiple
	//				top 7px
	//				img
	//					height 25px
	//		.illust
	//			img
	//				height 40px
	//		&.large
	//			float left
	//			margin 0 15px 0 0

	.top-map
		margin 0 10px 30px
		div
			float none
			margin 0 0 15px
			img
				width 100%
		dl
			float none
			width auto
			padding 0
		dt
			margin 0
			fz-rem(18)
		dd
			margin 0
			fz-rem(14)
			br
				display none
	
	.top-contents
		overflow visible
		width auto
		border-top 2px solid #8890CF
		header
			display none
		ul
			width auto
		li
			float none
			width auto
			min-height 55px
			margin-right 0
			margin-bottom 0
			padding 15px 12px
			border-bottom 1px solid #8890CF
			a
				position relative
				&:after
					position absolute
					content ""
					width 14px
					height 14px
					top 50%
					right 4px
					margin -7px 0 0 0
					padding-left 0
					border-top solid 2px #8890CF
					border-right solid 2px #8890CF
					transform rotate(45deg)
		.illust
			display block
			float left
			img
				width 55px
				margin 0
		.title
			display block
			margin 0 0 10px 67px
			img
				vertical-align middle
		.text
			width auto
			margin 0 40px 0 67px
			fz-rem(12)
			line-height 1.4

	.en
		margin-bottom 0
		padding 30px 12px
		border-top 2px solid #8890CF
		a
			width auto
			height 80px
		.title
			position absolute
			top 25px
			left 15px
			img
				width 130px
		.photo
			top 10px
			right 10px
			img
				width 64px
		.daiichi
			float none
			margin-bottom 10px
			a
				background #74AFCF
		.daini
			float none
			a
				background #7DB884

@media screen and (max-width: $viewport_width_2)
	.c-header-top
		.c-wrapper
			height 270px
		.title
			img
				width 93%
				margin 0 0 15px
			.name
				fz-rem(11)
				margin-left 16px
	.c-header-top-summer
		.illust
			top 130px
	.c-header-top-winter
		.illust
			img
				width 180px
	
	.c-header-daiichi
	.c-header-daini
		header
			background-image none
			h2
				position static
				top auto
				left auto
				padding 10px 0
				text-align center
				img
					width 50%
			.photo
				position static
				img
					width 100%
	.taisetsu
		.wrapper
			padding 0 50px
	.slick
		margin 0 50px
	.slick-slide
		padding 5px

	//.slider-button-prev
	//	left 10px
	//.slider-button-next
	//	right 10px

	.top-banner
		li
			&.large
				float none
				margin 0 0 10px
				img
					width 100%
	.top-map
		dt
			fz-rem(16)
		dd
			fz-rem(12)


